import { Layout } from 'antd';
import Navbar from './components/shared/Navbar';
import Homepage from './components/pages/Homepage';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import JobPost from './components/pages/JobPost';
import SearchJob from './components/pages/SearchJob';
import VerifyJob from './components/pages/VerifyJob';
import NRNAFooter from './components/shared/NRNAFooter';
import './styles/App.css';
import UpdateJob from './components/pages/UpdateJob';

function App() {
  const { Header, Footer, Content } = Layout;
  return (
    <Layout>
      <Header style={{ backgroundColor: '#006ba1', borderBottom: '1px solid #e8e8e8' }}>
        <Navbar />
      </Header>
      <Content style={{ padding: '20px' }}>
         <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/postjob" element={<JobPost />} />
              <Route path="/searchjob" element={<SearchJob />} />
              <Route path="/VerifyJob/:jobId" element={<VerifyJob />} />
              <Route path="/UpdateJob/:jobId" element={<UpdateJob />} />
            </Routes>
          </BrowserRouter>
      </Content>
      <Footer style={{ textAlign: 'center' }}><NRNAFooter /></Footer>
    </Layout>
  );
}

export default App;
