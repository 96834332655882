import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { FacebookOutlined, TwitterOutlined, EnvironmentOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const { Text, Link } = Typography;

const NRNAFooter = () => {
    return (
        <Footer style={{ backgroundColor: '#007bff', color: 'white', width: '100%' }}>
            <Row justify="center" style={{ padding: '20px' }}>
                <Col xs={24} sm={24} md={8} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <img src="https://nrnusa.org/static/assets/images/website_banner.png" alt="NRNA Logo" style={{ width: '50%', height: '30%' }} />
                    <Text style={{ display: 'block', marginTop: '10px', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>10520 Warwick Ave, Suite B6, Fairfax, VA 22030</Text>
                    <Text style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Tel: (571) 789-2671</Text>
                    <Text style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Email: info@nrnusa.org</Text>
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                        <a href="https://www.facebook.com/nrnanccusa/" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FacebookOutlined style={{ fontSize: '20px' }} />
                        </a>

                        <a href="https://www.facebook.com/nrnanccusa/" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <TwitterOutlined style={{ fontSize: '20px' }} />
                        </a>

                        <a href="https://www.facebook.com/nrnanccusa/" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <EnvironmentOutlined style={{ fontSize: '20px' }} />
                        </a>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={4} style={{ textAlign: 'center' }}>
                    <Text style={{ display: 'block', fontSize: '18px', color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>About NRN</Text>
                    <Link href="https://nrnusa.org/about-overview/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>About us</Link>
                    <Link href="https://nrnusa.org/project-overview/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Projects</Link>
                    <Link href="https://nrnusa.org/chapter-leadership/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Chapters and task force</Link>
                    <Link href="https://nrnusa.org/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Member Registration</Link>
                </Col>
                <Col xs={24} sm={12} md={4} style={{ textAlign: 'center' }}>
                    <Text style={{ display: 'block', fontSize: '20px', color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>Information</Text>
                    <Link href="https://nrnusa.org/event/list/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Events</Link>
                    <Link href="https://nrnusa.org/meeting-overview/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Meetings</Link>
                    <Link href="https://nrnusa.org/documentoverview/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Documents</Link>
                    <Link href="https://nrnusa.org/calendar-operations/annual-program/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Annual Program</Link>
                </Col>
                <Col xs={24} sm={12} md={4} style={{ textAlign: 'center' }}>
                    <Text style={{ display: 'block', fontSize: '18px', color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>Support</Text>
                    <Link href="https://nrnusa.org/contact/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Contact us</Link>
                    <Link href="https://nrnusa.org/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Terms of use</Link>
                    <Link href="https://nrnusa.org/election-commissions/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Election Commission</Link>
                    <Link href="https://nrnusa.org/privacy-and-policy/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>Privacy Policy</Link>
                    <Link href="https://nrnusa.org/faq/" target="_blank" style={{ display: 'block', fontSize: '14px', color: 'white', fontWeight: 'bold' }}>FAQ</Link>
                </Col>
            </Row>
            <Row justify="center" style={{ padding: '10px 0' }}>
                <Col style={{ textAlign: 'center' }}>
                    <Text style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>© 2024 NRNA NCC OF USA. All rights reserved.</Text>
                </Col>
            </Row>
            <style jsx>{`
                .social-icon {
                    display: inline-block;
                    width: 30px; /* Adjusted width */
                    height: 30px; /* Adjusted height */
                    margin: 0 5px; /* Adjusted margin */
                    border-radius: 50%;
                    background-color: black;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    opacity: 1 !important;
                }

                .social-icon:hover {
                    background-color: #555;
                }

                .social-icon > span {
                    color: white !important;
                }
            `}</style>
        </Footer>
    );
}

export default NRNAFooter;
