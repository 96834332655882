import { Input, Select, Typography, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Form } from "antd";
import getAllStates from '../../scripts/getStates';
import filterByInput from '../../scripts/helper';
import getCitiesByState from '../../scripts/getCities';
import { useEffect, useState } from 'react';
import { getJobCategories, getJobPreferences } from '../../scripts/getFormData';
import { postData } from '../../scripts/APIHelper';
import { openNotification } from '../../scripts/UI Scripts/notificationHelper';
import { getAPIUrlBase } from '../../scripts/APIconfig';
const { Title } = Typography;

// Gets all the states 
const states = getAllStates();
const stateOptions = Object.keys(states).map(state => ({
    value: states[state],
    label: state
}));

const mapJobCategoryandPreferences = (lists) => {
    return Object.keys(lists).map(item => ({
        value: lists[item],
        label: lists[item]
    }));
}


// Gets all Job Types
const jobCategories = getJobCategories();
const jobCategoriesOptions = mapJobCategoryandPreferences(jobCategories);
const jobPreferences = getJobPreferences();
const jobPreferencesOptions = mapJobCategoryandPreferences(jobPreferences);

const JobPost = () => {
    const [selectedState, setSelectedState] = useState('');
    const [cities, setCities] = useState([]);
    const [form] = Form.useForm();



    // When the form has been submitted
    const onFormFinished = (value) => {
        const postedDate = new Date();
        const expiryDate = new Date(postedDate);
        const apiUrl = getAPIUrlBase() + '/Jobs/PostJobs';
        let response = null;
        const job = {
            BusinessName: value.businessName, 
            JobTitle: value.jobTitle,
            Description: value.description,
            State: value.state,
            City: value.city,
            BusinessEmail: value.email,
            PhoneNumber: value.phone,
            Type: value.jobCategory,
            PostedDate: postedDate.toISOString(),
            ExpiryDate: expiryDate.toISOString(),
            location: value.jobPreference,
            emailAuthToken: "",
            isDeleted: false,
            isVerified: false,
            emailAuthTokenExpiryDate: "2024-03-24T22:59:04.152Z",
            locationPreference: value.jobPreference
        };
        (async () => {
            response = await postData(job, apiUrl);
            console.log(response);
        })();
        openNotification("success", "Job Posted", value.jobTitle + " Has been posted! Please check your email and verify");
        form.resetFields();
    }

    const onFormErrored = (value) => {
        console.log('Error:', value);
        openNotification("error", "Error", "Please make sure to enter all the fields properly");
    }


    useEffect(() => {
        if (selectedState !== '') {
            (async () => {
                let result = await getCitiesByState(selectedState);
                console.log(result);
                let cityOptions = Object.values(result).map(city => ({
                    value: city.name,
                    label: city.name
                }));
                setCities(cityOptions)
            })();
        }
    }, [selectedState]);

    return (
        <>
            <Form form={form} onFinish={onFormFinished} onFinishFailed={onFormErrored}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>
                        <Title level={2}>Job Post</Title>
                        <br />
                    </Typography>
                    <Form.Item name="businessName" style={{ width: '40%' }}
                        rules={[{ required: true, message: 'Please enter Business Name!' }]}>
                        <Input variant="filled" size='large' placeholder="Business Name" />
                    </Form.Item>
                    <Form.Item name="jobTitle" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Job Title!' }]} >
                        <Input variant="filled" size='large' placeholder="Job Title" />
                    </Form.Item>
                    <Form.Item name="description" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Description!' }]}  >
                        <TextArea variant="filled" size='large' placeholder="Description" />
                    </Form.Item>
                    <Form.Item name="email" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Email!' }]}  >
                        <Input variant="filled" size='large' placeholder="Email (Mandatory)" />
                    </Form.Item>
                    <Form.Item name="phone" style={{ width: '40%' }} >
                        <Input variant="filled" size='large' placeholder="Phone (Optional)" />
                    </Form.Item>
                    <Form.Item name="state" style={{ width: '40%' }} rules={[{ required: true, message: 'Please select a State!' }]} >
                        <Select placeholder="Select a State" size="large" variant='filled'
                            options={stateOptions}
                            showSearch
                            filterOption={filterByInput}
                            onChange={(e) => setSelectedState(e)} />
                    </Form.Item>
                    <Form.Item name="city" style={{ width: '40%' }} rules={[{ required: true, message: 'Please select a City!' }]}  >
                        <Select placeholder="Select a City" size="large" variant='filled'
                            options={cities}
                            showSearch
                            filterOption={filterByInput}
                        />
                    </Form.Item>
                    <Form.Item name="jobCategory" style={{ width: '40%' }} rules={[{ required: true, message: 'Please select a Job Category!' }]}  >
                        <Select placeholder="Select a Job Categories" size="large" variant='filled'
                            options={jobCategoriesOptions}
                            showSearch
                            filterOption={filterByInput}
                        />
                    </Form.Item>
                    <Form.Item name={"jobPreference"} style={{ width: '40%' }} rules={[{ required: true, message: 'Please select a Job Preference!' }]} >
                        <Select placeholder="Select a Job Perferences" size="large" variant='filled'
                            options={jobPreferencesOptions}
                            showSearch
                            filterOption={filterByInput}
                        />
                    </Form.Item>
                    <Button style={{ width: '20%' }} type="primary" htmlType="submit" size="large">Post Job</Button>
                </div>
            </Form>
        </>
    )
}

export default JobPost