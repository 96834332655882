import { notification } from 'antd';

const openNotification = (notificationType, notificationMessage, notificationDescription) => {
    if (notificationType === "success") {
        notification.success({
            message: notificationMessage,
            description: notificationDescription,
        });
    } else if (notificationType === "error") {
        notification.error({
            message: notificationMessage,
            description: notificationDescription,
        });
    } else if (notificationType === "info") {
        notification.info({
            message: notificationMessage,
            description: notificationDescription,
        });
    } else if (notificationType === "warning") {
        notification.warning({
            message: notificationMessage,
            description: notificationDescription,
        });
    }
};

export { openNotification };