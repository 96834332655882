import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Select, Pagination } from 'antd';
import { postData } from '../../scripts/APIHelper';
import { useMediaQuery } from 'react-responsive';
import getAllStates from '../../scripts/getStates';
import filterByInput from '../../scripts/helper';
import getCitiesByState from '../../scripts/getCities';
import { getJobCategories } from '../../scripts/getFormData';
import { openNotification } from '../../scripts/UI Scripts/notificationHelper';
import Search from 'antd/es/input/Search';
import Input from 'antd/es/input/Input';
import { getAPIUrlBase } from '../../scripts/APIconfig';


const columns = [
    {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        width: 200,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search Job Title"
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value, record) => record.jobTitle.toLowerCase().includes(value.toLowerCase()),
    },
    {
        title: 'Business Name',
        dataIndex: 'businessName',
        key: 'businessName',
        width: 200
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 300
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 200
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 200
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 200
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: 200
    },
    {
        title: 'Business Email',
        dataIndex: 'businessEmail',
        key: 'businessEmail',
        width: 200
    },
    {
        title: 'Expiry Date',
        dataIndex: 'expiryDate',
        key: 'expiryDate',
        width: 250
    },
];

const mapJobCategoryandPreferences = (lists) => {
    return Object.keys(lists).map(item => ({
        value: lists[item],
        label: lists[item]
    }));
}
const jobCategories = getJobCategories();
const jobCategoriesOptions = mapJobCategoryandPreferences(jobCategories);

const fetchJobs = async (selectedState, selectedCity, selectedJob) => {
    let filters = { state: selectedState, city: selectedCity, jobTypes: selectedJob };
    const NRNA_apiUrl = getAPIUrlBase() +'/Jobs/GetJobs';
    let result = await postData(filters, NRNA_apiUrl);
    if (result.isSuccess) {
        return result.data;
    }
    return [];
}

// Gets all the states 
const states = getAllStates();
const stateOptions = Object.keys(states).map(state => ({
    value: states[state],
    label: state
}));

const JobTable = () => {
    const [jobs, setJobs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [selectedState, setSelectedState] = useState('');
    const [cities, setCities] = useState([]);
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5); // Items per page
    const [jobTitleFilter, setJobTitleFilter] = useState('');

    useEffect(() => {
        const loadJobs = async () => {
            const data = await fetchJobs('', '', '');
            setJobs(data);
        }
        (async () => {
            await loadJobs()
        })();
    }, []);

    useEffect(() => {
        const loadCities = async () => {
            if (selectedState) {
                const citiesData = await getCitiesByState(selectedState);
                setCities(citiesData.map(city => ({
                    value: city.name,
                    label: city.name
                })));
            } else {
                setCities([]); // Clear cities if no state is selected
            }
        };
        loadCities();
    }, [selectedState]);

    const showModal = (job) => {
        setSelectedJob(job);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const renderMobileCards = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const filteredJobs = jobs.filter(job =>
            job.jobTitle.toLowerCase().includes(jobTitleFilter.toLowerCase())
        );

        return filteredJobs.slice(startIndex, endIndex).map(job => (
            <div key={job.jobId} style={cardStyle}>
                <h3>{job.jobTitle}</h3>
                <h4>{job.businessName}</h4>
                <h5>{job.city + ", " + job.state}</h5>
                <p>{job.description}</p>
                <Button type="primary" style={{ ...buttonStyle, marginTop: '10px' }} onClick={() => showModal(job)}>More Details</Button>
            </div>
        ));
    };


    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    // When the form has been submitted
    const onFormFinished = async (value) => {
        const data = await fetchJobs(value.state, value.city, value.jobCategory);
        openNotification("success", "Job Filtered");
        setJobs(data);
        setCurrentPage(1); // Reset to the first page
    }

    const clearForm = () => {
        form.resetFields();
        setCities([]); // Clear cities when resetting form
        openNotification("success", "The Filters have been cleared");
    }

    const buttonStyle = isMobile ? {
        width: '30%',
        fontSize: '16px'
    } : {
        width: '10%',
        fontSize: '14px'
    };

    const cardStyle = {
        backgroundColor: '#f9f9f9',
        padding: '15px',
        borderRadius: '5px',
        marginBottom: '15px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
    };

    return (
        <>
            <Form form={form} onFinish={onFormFinished}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                    <Form.Item style={{ width: '30%' }} name="state"  >
                        <Select placeholder="Select a State" size="large" variant='filled'
                            options={stateOptions}
                            showSearch
                            filterOption={filterByInput}
                            onChange={(e) => setSelectedState(e)} />
                    </Form.Item>
                    <Form.Item style={{ width: '30%' }} name="city" >
                        <Select placeholder="Select a City" size="large" variant='filled'
                            options={cities}
                            showSearch
                            filterOption={filterByInput}
                            disabled={!selectedState} // Disable until a state is selected
                        />
                    </Form.Item>
                    <Form.Item style={{ width: '30%' }} name="jobCategory"  >
                        <Select placeholder="Select a Job Category" size="large" variant='filled'
                            options={jobCategoriesOptions}
                            showSearch
                            filterOption={filterByInput}
                        />
                    </Form.Item>
                    <Button style={{ ...buttonStyle, marginBottom: '5px' }} type="primary" htmlType="submit" size="large">Search Job</Button>
                </div>
            </Form>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                <Button style={{ ...buttonStyle, marginBottom: '45px' }} type="primary" size="large" onClick={clearForm}>Reset Filters</Button>
            </div>
            {isMobile ? (
                <>
                    <Input
                        placeholder="Search Job Title"
                        value={jobTitleFilter}
                        onChange={(e) => setJobTitleFilter(e.target.value)}
                        style={{ width: '90%', marginBottom: '15px' }}
                    />

                    {renderMobileCards()}
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={jobs.filter(job =>
                            job.jobTitle.toLowerCase().includes(jobTitleFilter.toLowerCase())
                        ).length}
                        onChange={handlePaginationChange}
                        showSizeChanger
                    />
                </>
            ) : (
                <Table
                    columns={columns}
                    dataSource={jobs}
                    rowKey="jobId"
                    scroll={{ x: 1300 }}
                />
            )}
            {selectedJob && (
                <Modal
                    title={<span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{selectedJob.jobTitle}</span>}
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}>
                    <p><strong>Business Name:</strong> {selectedJob.businessName}</p>
                    <p><strong>Business Email:</strong> {selectedJob.businessEmail}</p>
                    <p><strong>State:</strong> {selectedJob.state}</p>
                    <p><strong>City:</strong> {selectedJob.city}</p>
                    <p><strong>Phone Number:</strong> {selectedJob.phoneNumber}</p>
                    <p><strong>Type:</strong> {selectedJob.type}</p>
                    <p><strong>Description:</strong> {selectedJob.description}</p>
                    <p><strong>Expiry Date:</strong> {new Date(selectedJob.expiryDate).toISOString().split('T')[0]}</p>
                </Modal>
            )}
        </>
    );
}

export default JobTable;
