import { Button, Typography, Row, Col } from "antd";

const Homepage = () => {
    const { Title } = Typography;
    return (
        <>
            <Row justify="center">
                <Col xs={24} sm={18} md={16} lg={12}>
                    <Typography>
                        <Title level={1} style={{ textAlign: 'center', color: 'red' }}>Disclaimer</Title>
                        <p style={{ textAlign: 'center', color: 'black', marginBottom: '1px' }}>
                            The job search link provided on this website is intended to assist Non-Resident Nepalese and other individuals
                            in finding jobs in the USA. Please, read this disclaimer carefully before submitting your personal information.
                            By using this link, you acknowledge and agree to the following terms:
                        </p>
                    </Typography>
                </Col>
            </Row>
            <Row justify="center" style={{ padding: '20px' }}>
                <Col xs={24} sm={18} md={16} lg={12}>
                    <Typography>
                        <ol style={{ color: 'black', margin: '0', padding: '0' }}>
                            <li>
                                <strong >Personal Information Responsibility:</strong> You are solely responsible for any personal information
                                you submit when applying for jobs through this portal. Please, exercise caution and protect your sensitive data.
                            </li>
                            <li>
                                <strong>Unmonitored Platform:</strong> This job search link and the associated website are unmonitored. NRNA NCC, USA
                                a non-profit organization legally registered in the USA under the terms of section 501(c)(3) of the Internal Revenue
                                Code of the United States of America, does not oversee the content or operations of the job listings provided
                                through this link.
                            </li>
                            <li>
                                <strong>Liability Disclaimer:</strong> NRNA NCC USA is not responsible for any risks associated with personal data breaches,
                                misuse of personal information, or any other risks related to your use of this job search link.
                            </li>
                            <li>
                                <strong>No Legal Claims:</strong> By using this link, you agree that you will not have any right to dispute, file claims, lawsuits, or
                                charges against NRNA NCC USA for any issues arising from your use of this job search link or any associated risks.
                            </li>
                        </ol>
                    </Typography>
                </Col>
            </Row>

            <Row justify="center" style={{ padding: '20px' }}>
                <Col xs={24} sm={18} md={16} lg={12} style={{ display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <Title level={2}>Welcome to NRNA Job Post!</Title>
                    <Button style={{ width: '100%' }} type="primary" size="large" href="/postjob">Post Job</Button>
                    <Button style={{ width: '100%' }} type="primary" size="large" href="/searchjob">Search Job</Button>
                    <Button style={{ width: '100%' }} type="primary" size="large" href="https://nrnusa.org/">Back to NRNA</Button>
                </Col>
            </Row>
        </>
    );
}

export default Homepage;
