const deleteDataById = async (url,id) => {
    try {
        let response = null;
        let uri = url + "/" + id;
        console.log(uri);
        if(id !== null) {
            response = await fetch(uri, {
                "method": "DELETE"
            });

            if(!response.ok){
                throw new Error("Network response was not ok");
            }

            return await response.json();
        }
    } catch (err) {
        throw new Error(err);
    }
}


const getDataByUrl = async (url, apiKey, data) => {
    try {
        let response = null;
        if (apiKey !== null) {
            response = await fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "X-CSCAPI-KEY": apiKey
                }
            });
        }
        else {
            response = await fetch(url, {
                method: "GET"
            });
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (e) {
        throw e;
    }

}


const updateJob = async (data, url) => {
    try {
        if(data !== null & url != '') {
            let response = null;
            let jsonData = JSON.stringify(data);
            response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: jsonData
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return true
        }
        return false;
    } catch (e) {
        throw new Error(e)
    }
}

const postData = async (data, url) => {
    try {
        let response = null;
        if (data !== null) {
            let jsonData = JSON.stringify(data);
            response = await fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: jsonData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        }
    } catch (e) {
        throw e;
    }
}


const patchData = async (data, url) => {
    let response = null;
    if (data !== null) {

    } else {
        console.log(url);
        const response = await fetch(url, {
            method: 'PATCH'
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }
}


const getById = async (url, jobId) => {
    let response = null;
    console.log(url + "/" + jobId);
    if (jobId !== '' && url !== '') {
        let uri = url + "/" + jobId;
        response = await fetch(uri, {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    }
    return response;
}

export { getDataByUrl, postData, patchData, getById, updateJob,deleteDataById }