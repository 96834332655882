import { Typography, Avatar, Menu } from 'antd';
import NRNALogo from '../../images/NRNALogo.png';

const { Title } = Typography;

const Navbar = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '0 20px'}}>
            <Avatar 
                            src={NRNALogo} 
                            size="large" 
                            style={{ marginRight: '20px', height: '60px', width: '60px' }} 
                        />
            <Title level={2} style={{ margin: 0, flexGrow: 1, color: 'white' }}>NRNA Job Posting</Title>
        </div>
    );
}

export default Navbar;
