import { useParams, useNavigate   } from "react-router-dom";
import { Button, Form, Input, Typography } from 'antd';
import { patchData } from "../../scripts/APIHelper";
import { openNotification } from "../../scripts/UI Scripts/notificationHelper";
import { getAPIUrlBase } from "../../scripts/APIconfig";
const { Title } = Typography;



const VerifyJob = () => {
    let { jobId } = useParams();
    const navigate = useNavigate();
    const onFormFinished = (Form) => {
        patchData(null, `${getAPIUrlBase()}/Jobs/VerifyJob/${jobId}/${Form.token}`);
        openNotification("success", "Job Verified", "Your job has been verified, you will be redirected shortly");
        setTimeout(() => {
            navigate('/'); 
          }, 500); 
    }
    
    const onFormErrored = (Form) => {
        console.error("Error while submitting the form");
        openNotification("error", "Error", "Please make sure to enter all the fields properly");
    }
    return (
        <>
            <Form  onFinish={onFormFinished} onFinishFailed={onFormErrored}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                    <Title level={3}>Please Verify</Title>
                    <Form.Item name="token" rules={[{ required: true, message: 'Please enter your Verification Token!' }]}>
                        <Input.OTP  size="large" variant="filled" />
                    </Form.Item>
                    <Button style={{ width: '15%' }} type="primary" htmlType="submit" size="large">Verify Job</Button>
                </div>
            </Form>
        </>
    )
}

export default VerifyJob;