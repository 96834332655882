import {getDataByUrl} from "./APIHelper";

const getCitiesByState = async (state) => {
    const apiUrl = `https://api.countrystatecity.in/v1/countries/US/states/${state}/cities`;
    const apiKey = "Q2NWRHZ1aldqRlFRelZab0hJZ0dzdHNyNEdYVk5hWDI1Y25aVE1vSg=="; 
    try {
       let data = await getDataByUrl(apiUrl, apiKey);
       return data;
    } catch (e) {
        throw e;
    }
}


export default getCitiesByState