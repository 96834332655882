const getJobCategories = () => {
    return [
        'Full-Time',
        'Part-Time'
    ];
}

const getJobPreferences = () => {
    return [
        'In-Person',
        'Remote',
        'Hybrid'
    ];    
}

export {
    getJobCategories,
    getJobPreferences
};
