import { useParams,useNavigate } from "react-router-dom";
import { deleteDataById, getById, patchData, updateJob } from "../../scripts/APIHelper";
import { Button, Form, Input, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/skeleton/Title";
import { useEffect, useState } from "react";
import { openNotification } from "../../scripts/UI Scripts/notificationHelper";
import { getAPIUrlBase } from '../../scripts/APIconfig';


const UpdateJob = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const navigate = useNavigate();

    const onDelete = async () => {
        await deleteDataById(getAPIUrlBase() + '/Jobs/DeleteJobs', jobId);
        openNotification("success", "Success", "Job was deleted successfully!");
        setTimeout(() => {
            navigate('/'); 
          }, 500); 

    } 

    const onJobUpdate = async (value) => {
        job.businessName = value.businessName;
        job.jobTitle = value.jobTitle;
        job.description = value.description;
        job.businessEmail = value.email;
        job.phoneNumber = value.phone;
        console.log(job);
        let result = await updateJob(job, getAPIUrlBase() + '/Jobs/UpdateJob');
        if (result === true) {
            openNotification("success", "Success", "Job was updated successfully!");
            setTimeout(() => {
                navigate('/'); 
              }, 500); 
        } else {
            openNotification("warning", "Warning", "Job was not updated!");
        }
    }
    useEffect(() => {
        (async () => {
            try {
                let response = await getById(getAPIUrlBase() + '/Jobs/GetJobByID', jobId);
                let data = response.data;
                console.log(data);
                setJob(data);
            } catch (error) {
                console.error('Error fetching job:', error);
            }
        })();
    }, []);

    return (
        <>
            <div>
                {job ? (
                    <div>
                        <Form
                            initialValues={{
                                businessName: job.businessName,
                                jobTitle: job.jobTitle,
                                description: job.description,
                                email: job.businessEmail,
                                phone: job.phoneNumber,
                            }}
                            onFinish={onJobUpdate}
                        >
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>
                                    <Title level={2}>Job Post</Title>
                                    <br />
                                </Typography>
                                <Form.Item name="businessName" style={{ width: '40%' }}
                                    rules={[{ required: true, message: 'Please enter Business Name!' }]}>
                                    <Input variant="filled" size='large' placeholder="Business Name" />
                                </Form.Item>
                                <Form.Item name="jobTitle" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Job Title!' }]} >
                                    <Input variant="filled" size='large' placeholder="Job Title" />
                                </Form.Item>
                                <Form.Item name="description" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Description!' }]}  >
                                    <TextArea variant="filled" size='large' placeholder="Description" />
                                </Form.Item>
                                <Form.Item name="email" style={{ width: '40%' }} rules={[{ required: true, message: 'Please enter Email!' }]}  >
                                    <Input variant="filled" size='large' placeholder="Email (Mandatory)" />
                                </Form.Item>
                                <Form.Item name="phone" style={{ width: '40%' }} >
                                    <Input variant="filled" size='large' placeholder="Phone (Optional)" />
                                </Form.Item>
                                <Button style={{ marginBottom: '5px' }} type="primary" htmlType="submit" size="large">Update Job</Button>
                            </div>
                        </Form>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button style={{ marginBottom: '5px', background: 'red' }} type="primary" htmlType="submit" size="large" onClick={onDelete}>Delete Job</Button>
                        </div>

                    </div>
                ) : (
                    <p>Loading job details...</p>
                )}
            </div>
        </>

    );
};

export default UpdateJob;


